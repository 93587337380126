<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Hotel information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Hotel Name" label-position="inside">
                        <b-input v-model="hotel.title"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Location" label-position="inside">
                        <b-input v-model="hotel.location"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";

    export default {
        name: 'HotelForm',
        props: {
            hotel: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList}
    };
</script>
